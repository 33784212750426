

.bundle-card
    position relative
    // border: 1px solid $color-bnn-gray-light
    background white
    border-radius: 8px;
    display: flex
    justify-content: center
    flex-wrap: wrap
    gap: 16px 0;
    // grid-template-columns: repeat(1, 1fr);
    height: 360px;
    padding 1rem 0
    &:hover
        cursor pointer
    img {
        max-width:150px;
        max-height:150px;
        width: 100%
        height: 100%
        object-fit: contain
    }
    div {
        width 100%
    }

.bundle-name {
   display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 50px
    padding-top: 1rem;
}

.bundle-card.disabled
    opacity 0.4
    cursor: not-allowed

.bundle-price {
    font-size: 1.2rem
    font-weight: bold
    color: $color-bnn-red-2
    margin-top: -1rem
    padding-left: 2rem
    text-align: left
    bottom: 0
    left: 0
}

.bundle-card.select
    border: 1px solid $color-bnn-gray-medium;

.v-btn-cancel-item
  font-weight: bold !important
  font-size: 0.875rem !important
  padding: 18.5px 0 !important
  border-color: $color-bnn !important
  background-color: $color-fourth !important

.v-text-cancel-item
  color: $color-secondary-text

.normal-price
  color: $color-bnn-gray-medium-2
  font-size: 0.85rem
  text-align: left
  height: 22px

.deposit-price
  color: $color-bnn-gray-medium-2
