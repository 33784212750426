

.summary-cart
  height: auto;
  width: 100%
  bottom: 0
  left: 0
  position: fixed
  z-index 50
  background-color: $color-dapp-gray-4;
  box-shadow: 0 0.5px 0 0 $color-gray83 inset;
  // background: #fff
  // box-shadow: 1px 1px 2px 1px $color-gray-200;

.detail-content
  display: flex
  align-items: center
  height: 110px !important
  @media small
    margin-bottom: 150px

.action-layout
  width: 100%
  display: flex
  justify-content: flex-end
  padding: 20px 12px
  .action
        width 100%
        display: flex
        flex-direction: row
        gap: 16px
        grid-template-columns: repeat(2, 1fr)
        align-items: center
        justify-content: space-between
        @media screen and (max-width: 960px)
          width 100%
          grid-template-columns: repeat(2, 1fr)
        .btn-back
          width: 50%
          @media screen and (max-width: 960px)
            width: 100%

        .btn-next
           width: fit-content

.price
        width: 100%
        display: flex
        justify-content: flex-start
        flex-direction: column
        align-items: flex-end
        .price-text
            font-size: 1.15rem
            color:  $color-bnn-red-2
            text-align: right
            @media screen and (max-width: 960px)
               font-size: 0.875rem

.summary
    display: flex
    justify-content: space-between
    margin: 1rem 0
    font-size: 1.15rem
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    .count-number
      width: 50%
      color: $color-bnn-gray-medium-2
      @media screen and (max-width: 960px)
         font-size: 0.875rem
    .price-text
      width 50%
      display: flex
      flex-wrap: nowrap
      flex-direction: row
      justify-content: flex-end
      @media screen and (max-width: 960px)
         font-size: 0.875rem
      .price
        color: $color-text-price
        font-weight: bold
        width auto
        margin-left: 0.2rem

.summary-cart-price-detail
    display: flex
    flex-direction: column
    // justify-content: space-between
    margin: 1rem 0
    font-size: 1.15rem

.price-summary-text
    color:  $color-text-price

.v-btn-back
  font-weight: bold !important
  font-size: 0.875rem !important
  padding: 18.5px 0 !important
  border-color: $color-secondary !important

.v-text-back
  color: $color-secondary-text

.price-summary-cart-3-lines
  display: flex
  justify-content: space-between
  align-items: center
  font-size: 1 rem

.text-discount
  color $color-bnn-red

.btn-link {
    padding 0 !important
    color: $color-bnn;
    cursor pointer

    &:hover:before {
        background: transparent !important
    }

    .v-icon {
        color: $color-bnn
    }
}
