

.angle-icon {
    width: 36px
    height: 36px
    background: $color-bnn-gray-medium-2
    border-radius: 50px
    display: flex
    justify-content: center
    align-items: center
}

.swiper {
  // height: 350px;
  width: 100%;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
    background: white
  }
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left 0 !important
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right 0 !important
}

.swiper-button-next::after, .swiper-button-prev::after {
    content: "" !important
}

.swiper-button-next, .swiper-button-prev {
    color: white !important
    width auto !important
    margin-top: -35px
}

::v-deep .swiper-pagination-bullet-active {
  width: 24px !important
  background: $color-bnn-gray-medium 0% 0% no-repeat padding-box !important;
  border-radius: 2px !important;
}

::v-deep .swiper-pagination-bullet {
  width: 16px
  background: $color-bnn-gray-light 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity 1
}

.swiper-addition-product {
  padding-bottom: 1.5rem;
}

.swiper-pagination-addition-product {
  bottom: 0
}

.swiper-slide-active,.swiper-slide-next  {
  @media small {
    width: calc(100% - 120px) !important
  }

  @media tablet {
    width: calc(100% - 120px) !important
  }

}
