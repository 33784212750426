

.customer-also-bought {
  background: $color-bnn-gray-light-2 0% 0% no-repeat padding-box
  height 100%
  width 100%
  margin-top: 75px
}

.customer-also-bought {
    background: #fff
    width 100%
    height 100%
  }

.title-customer-also-bought {
    padding: 0 0 2.5rem 0

    @media small {
      font-size: 0.85rem
    }
}

.footer-margin
    margin-bottom: 60px;

@media screen and (max-width: 960px)
  .footer-margin
    margin-bottom: 60px;
