

.product-main-layout
  display: flex
  justify-content: space-between
  width 100%
  .desc-main
    width 50%
    display: flex
    flex-direction: column
    justify-content: flex-start
    height 100%
    @media small
      justify-content: flex-start
      width 70%
    @media tablet
      justify-content: flex-start
    .product-name
                color: $color-bnn-gray-medium
                font-weight: bold
                margin-top: 0.5rem
    .product-sku
                color: $color-bnn-gray-medium-2
  .price-main
    width 50%
    display: flex
    flex-direction: column
    justify-content: flex-start
    height 100%
    text-align: right
    @media small
      width 30%
    @media tablet
      justify-content: space-between
    .price-text, .price-text-2
          font-size: 1rem
          // color:  $color-text-price
          text-align: right
          @media screen and (max-width: 768px)
            font-size: 0.9rem
            margin-top: 0.5rem
    .price-text-2
          //  color: $color-bnn !important


.product-card
    // background: white 0% 0% no-repeat padding-box;
    border-radius: 8px;
    display: flex
    justify-content: space-between
    flex: 1 1
    // padding: 1.25rem 3rem
    // margin-top: 40px
    flex-direction: column
    @media screen and (max-width: 768px)
      padding: 1rem 0.5rem 0 0.5rem
      font-size: 0.85rem
    .preview
        width: 100%
        display: flex
        justify-content: flex-start
        gap: 16px 16px
        margin: 1rem 0
        .img
            width: 75px
            height: 75px
            min-width: 75px
            max-width: 75px
            min-height: 75px
            max-height: 75px
            // height: 100%
            object-fit: contain
        .desc
            display: flex
            flex-direction: column
            justify-content: flex-start
            width: 100%
            .product-name
                color: $color-bnn-gray-medium
                font-weight: bold
                margin-top: 0.5rem
            .product-sku
                color: $color-bnn-gray-medium-2

    .price
        width: 100%
        display: flex
        justify-content: flex-start
        flex-direction: column
        align-items: flex-end
        .price-text
            font-size: 1rem
            color:  $color-text-price
            text-align: right
            @media screen and (max-width: 768px)
              font-size: 0.9rem
              margin-top: 0.5rem
.action-layout
  width: 100%
  display: flex
  justify-content: flex-end
  margin: 1.5rem 0
  .action
        width 50%
        display: grid
        gap: 16px
        grid-template-columns: repeat(2, 1fr)

.summary
    display: flex
    justify-content: space-between
    margin: 1rem 0
    .count-number
      width: 50%
      color: $color-bnn-gray-medium-2
    .price-text
      width 50%
      display: flex
      flex-wrap: nowrap
      flex-direction: row
      justify-content: flex-end
      .price
        color: $color-bnn-red-2
        font-weight: bold
        width auto
        margin-left: 0.2rem

.justify-between
    justify-content: space-between !important

.margin-top-0-5
    margin-top: 0.5rem

.drop-full-payment
  font-size: 0.875rem !important
  color: $color-bnn-gray-medium-2
